<template>
	<div style="height: 100%">
		<div style="padding: 20px; height: 800px">
			<!-- 搜素框 -->
			<el-form :inline="true" :model="params" class="form-userEntry" size="small">
				<el-form-item label="综合">
					<el-input v-model="params.content" placeholder="姓名/电话号码/身份证号" />
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="checkData" size="mini"> 查询 </el-button>
					<el-button type="info" @click="reset" size="mini">重置</el-button>
				</el-form-item>
			</el-form>
			<!-- 表格 -->
			<div class="grid">
				<el-table
					:data="tableData"
					stripe
					height="80%"
					style="margin-left: 20px; width: 98%"
					size="small"
					:header-cell-style="{
						'background-color': '#84aace4a',
						color: '#343333',
						'text-align': 'center',
					}"
					:cell-style="{ 'text-align': 'center' }"
				>
					<el-table-column prop="name" label="姓名" width="120"></el-table-column>
					<el-table-column prop="sex" label="性别" width="60"></el-table-column>
					<el-table-column prop="age" label="年龄" width="60"></el-table-column>
					<el-table-column prop="telephone" label="手机号" width="120"></el-table-column>
					<el-table-column
						prop="idCard"
						label="身份证"
						:show-overflow-tooltip="true"
						width="200"
					></el-table-column>
					<el-table-column prop="job" label="岗位" :formatter="jobFormat"></el-table-column>
					<el-table-column
						prop="createTime"
						label="申请时间"
						:show-overflow-tooltip="true"
						:formatter="(row) => dateTimeFormat(row.createTime)"
						width="180"
					></el-table-column>
					<el-table-column
						prop="approveStatus"
						label="审核状态"
						:show-overflow-tooltip="true"
						:formatter="statusFormat"
					></el-table-column>
					<el-table-column
						prop="approveTime"
						label="审核时间"
						:show-overflow-tooltip="true"
						:formatter="(row) => dateTimeFormat(row.approveTime)"
						width="180"
					></el-table-column>
					<el-table-column prop="approveName" label="审核人" width="120"></el-table-column>
					<el-table-column label="操作" fixed="right" width="200">
						<template slot-scope="scope">
							<el-tooltip
								effect="dark"
								content="审核通过"
								placement="top-end"
								v-show="
									authorization('/home-admin/routineManage/userEntry-manage/audit') &&
									isApproveStatus(scope.row)
								"
							>
								<el-button
									type="success"
									icon="el-icon-check"
									circle
									size="mini"
									@click="approveSuccess(scope.row)"
								></el-button>
							</el-tooltip>
							<el-tooltip
								effect="dark"
								content="驳回"
								placement="top-end"
								v-show="
									authorization('/home-admin/routineManage/userEntry-manage/audit') &&
									isApproveStatus(scope.row)
								"
								style="margin-left: 10px"
							>
								<el-popconfirm title="确认驳回吗？" @confirm="approveFun(scope.row, 2)">
									<el-button
										content="驳回"
										slot="reference"
										type="warning"
										icon="el-icon-close"
										circle
										size="mini"
									></el-button>
								</el-popconfirm>
							</el-tooltip>
							<el-tooltip
								effect="dark"
								content="删除"
								placement="top-end"
								v-show="authorization('/home-admin/routineManage/userEntry-manage/del')"
								style="margin-left: 10px"
							>
								<el-popconfirm title="确认删除吗？" @confirm="deleteUserEntry(scope.row)">
									<el-button
										content="删除"
										slot="reference"
										type="danger"
										icon="el-icon-delete"
										circle
										size="mini"
									></el-button>
								</el-popconfirm>
							</el-tooltip>
							<el-tooltip effect="dark" content="详情" placement="top-end">
								<el-button
									type="info"
									icon="el-icon-tickets"
									circle
									size="mini"
									@click="details(scope.row)"
									style="margin-left: 10px"
									v-show="authorization('/home-admin/routineManage/userEntry-manage/detail')"
								></el-button>
							</el-tooltip>
						</template>
					</el-table-column>
				</el-table>
				<div class="pager">
					<el-pagination
						background
						:page-sizes="sizes"
						layout="total,sizes,prev,pager,next"
						:total="total"
						@current-change="handleCurrentChange"
						@size-change="sizeChange"
					></el-pagination>
				</div>
			</div>
			<!--审核弹出层-->
			<!-- <el-dialog
				title="审核通过&完善信息"
				:visible.sync="dialogFormAdd"
				width="700px"
				:close-on-click-modal="false"
				top="10px"
			>
				<el-form
					:model="userArray"
					:rules="rules"
					ref="userArray"
					label-width="160px"
					size="small"
					style="width: 600px"
				>
					<el-form-item label="账号" prop="account">
						<el-input v-model="userArray.account"></el-input>
					</el-form-item>
					<el-form-item label="姓名" prop="userName">
						<el-input v-model="userArray.userName"></el-input>
					</el-form-item>
					<el-form-item label="联系电话" prop="phone">
						<el-input v-model="userArray.phone"></el-input>
					</el-form-item>
					<el-form-item label="通讯地址" prop="address">
						<el-input v-model="userArray.address"></el-input>
					</el-form-item>
					<el-form-item label="身份证" prop="idCard">
						<el-input v-model="userArray.idCard"></el-input>
					</el-form-item>
					<el-form-item label="在职状态" prop="quitStatus">
						<el-select v-model="userArray.quitStatus" placeholder="请选择区域" style="width: 100%">
							<el-option label="在职" :value="1"></el-option>
							<el-option label="离职" :value="2"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="接单状态" prop="receivingOrder">
						<el-select
							v-model="userArray.receivingOrder"
							placeholder="请选择接单状态"
							style="width: 100%"
						>
							<el-option label="正常接单" :value="0"></el-option>
							<el-option label="暂不接单" :value="1"></el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="入职日期">
						<el-date-picker
							style="width: 100%"
							v-model="userArray.inductionTime"
							type="date"
							value-format="timestamp"
							placeholder="选择日期"
						>
						</el-date-picker>
					</el-form-item>
					<el-form-item label="离职日期">
						<el-date-picker
							style="width: 100%"
							v-model="userArray.quitTime"
							type="date"
							value-format="timestamp"
							placeholder="选择日期"
						>
						</el-date-picker>
					</el-form-item>
					<el-form-item label="图片信息" prop="imageUrl">
						<el-upload
							action="#"
							:file-list="commFileList"
							list-type="picture-card"
							:auto-upload="false"
							:on-change="commChange"
							:on-remove="commRemove"
						>
							<i class="el-icon-plus"></i>
						</el-upload>
					</el-form-item>
					<el-form-item label="所属区域" prop="regionIdList">
						<el-select
							multiple
							v-model="userArray.regionIdList"
							placeholder="请选择区域"
							@change="regionChange"
							style="width: 100%"
						>
							<el-option
								v-for="item in regionList"
								:key="item.id"
								:label="item.regionName"
								:value="item.id"
							></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="所属角色" prop="roleIdList">
						<el-select
							multiple
							v-model="userArray.roleIdList"
							placeholder="请选择角色"
							@change="roleChange"
							style="width: 100%"
						>
							<el-option
								v-for="item in roleList"
								:key="item.id"
								:label="item.roleName"
								:value="item.id"
							></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="用户状态" prop="status">
						<el-select v-model="userArray.status" placeholder="请选择" style="width: 100%">
							<el-option :key="0" label="正常登录" :value="0"></el-option>
							<el-option :key="1" label="禁止登录" :value="1"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="微信唯一标识" prop="openId">
						<el-input v-model="userArray.openId"></el-input>
					</el-form-item>
				</el-form>
				<div slot="footer" style="margin-top: 50px">
					<el-button @click="dialogFormAdd = false" size="mini">取 消</el-button>
					<el-button type="primary" @click="addSubMit('userArray')" size="mini">确 定</el-button>
				</div>
			</el-dialog> -->

			<el-dialog
				title="入职申请详情"
				:visible.sync="dialogShowDetail"
				width="800px"
				:close-on-click-modal="false"
				top="10px"
			>
				<el-descriptions
					:column="2"
					border
					size="mini"
					:labelStyle="{ width: '15%' }"
					:contentStyle="{ width: '35%' }"
					style="
						background-color: #fff;
						padding: 20px;
						padding-bottom: 0;
						border-radius: 8px;
						border-top-left-radius: 0%;
						border-top-right-radius: 0%;
					"
				>
					<el-descriptions-item label="姓名">{{ detailRow.name }}</el-descriptions-item>
					<el-descriptions-item label="手机号码">{{ detailRow.telephone }}</el-descriptions-item>
					<el-descriptions-item label="身份证号">{{ detailRow.idCard }}</el-descriptions-item>
					<el-descriptions-item label="年龄">{{ detailRow.age }}</el-descriptions-item>
					<el-descriptions-item label="性别">{{ detailRow.sex }}</el-descriptions-item>
					<el-descriptions-item label="岗位">{{ jobFormat(detailRow) }}</el-descriptions-item>
					<el-descriptions-item label="电工证" v-if="detailRow.job == '0'">{{
						detailRow.electricianLicense
					}}</el-descriptions-item>
					<el-descriptions-item label="电工证到期日" v-if="detailRow.job == '0'">{{
						dateFormat(detailRow.electricianExpDate)
					}}</el-descriptions-item>
					<el-descriptions-item label="现居住地址">{{ detailRow.address }}</el-descriptions-item>
				</el-descriptions>
				<el-descriptions
					direction="vertical"
					:column="3"
					border
					size="mini"
					style="
						background-color: #fff;
						padding: 20px;
						padding-top: 5px;
						border-bottom-left-radius: 8px;
						border-bottom-right-radius: 8px;
					"
				>
					<el-descriptions-item
						label="身份证人像面照片"
						labelStyle="width: 25%;"
						contentStyle="height:200px"
					>
						<el-image
							:src="transformUrl(detailRow.idCardFrontImageUrl)"
							style="height: 200px"
							v-show="detailRow.idCardFrontImageUrl !== null && detailRow.idCardFrontImageUrl != ''"
							:preview-src-list="new Array(1).fill(transformUrl(detailRow.idCardFrontImageUrl))"
						></el-image>
					</el-descriptions-item>
					<el-descriptions-item
						label="身份证国徽面照片"
						labelStyle="width: 25%;"
						contentStyle="height:200px"
					>
						<el-image
							:src="transformUrl(detailRow.idCardBackImageUrl)"
							style="height: 200px"
							v-show="detailRow.idCardBackImageUrl !== null && detailRow.idCardBackImageUrl != ''"
							:preview-src-list="new Array(1).fill(transformUrl(detailRow.idCardBackImageUrl))"
						></el-image>
					</el-descriptions-item>
					<el-descriptions-item
						label="电工证照片"
						labelStyle="width: 25%;"
						contentStyle="height:200px"
						v-if="detailRow.job == '0'"
					>
						<el-image
							:src="transformUrl(detailRow.electricianImageUrl)"
							style="height: 200px"
							v-show="detailRow.electricianImageUrl !== null && detailRow.electricianImageUrl != ''"
							:preview-src-list="new Array(1).fill(transformUrl(detailRow.electricianImageUrl))"
						></el-image>
					</el-descriptions-item>
					<el-descriptions-item
						label="银行卡照片"
						labelStyle="width: 25%;"
						contentStyle="height:200px"
					>
						<el-image
							:src="transformUrl(detailRow.bankCardImageUrl)"
							style="height: 200px"
							v-show="detailRow.bankCardImageUrl !== null && detailRow.bankCardImageUrl != ''"
							:preview-src-list="new Array(1).fill(transformUrl(detailRow.bankCardImageUrl))"
						></el-image>
					</el-descriptions-item>
					<el-descriptions-item label="自拍照" labelStyle="width: 25%;" contentStyle="height:200px">
						<el-image
							:src="transformUrl(detailRow.selfImageUrl)"
							style="height: 200px"
							v-show="detailRow.selfImageUrl !== null && detailRow.selfImageUrl != ''"
							:preview-src-list="new Array(1).fill(transformUrl(detailRow.selfImageUrl))"
						></el-image>
					</el-descriptions-item>
				</el-descriptions>
			</el-dialog>
			<el-dialog
				title="审核通过&完善信息"
				:visible.sync="dialogFormAdd"
				width="700px"
				:close-on-click-modal="false"
				top="10px"
			>
				<UserAdd
					:data="userArray"
					ref="userAdd"
					@sureClick="addSubMit"
					@cancleClick="dialogFormAdd = false"
				/>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import { authorization } from '../../../authorization/authorization';
	import { dateFormat, dateTimeFormat } from '../common/format';
	import UserAdd from '../routineManage/UserAdd.vue';
	export default {
		components: { UserAdd },
		name: 'UserEntry',
		data() {
			return {
				dialogFormAdd: false,
				dialogShowDetail: false,
				detailRow: {},
				userArray: {},
				params: {
					content: '',
					userName: '',
					telephone: '',
					page: 1,
					size: 10,
				},
				tableData: [],
				total: 0,
				sizes: [10, 15],
				rules: {
					account: [
						{ required: true, message: '请输入账号', trigger: 'blur' },
						{ min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' },
					],
					userName: [
						{ required: true, message: '请输入姓名', trigger: 'blur' },
						{ min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' },
					],
					phone: [
						{ required: true, message: '请输入手机号', trigger: 'blur' },
						{ validator: this.validatePhone },
					],
					regionIdList: [
						{
							type: 'array',
							required: true,
							message: '请选择所属区域',
							trigger: ['blur', 'change'],
						},
					],
					roleIdList: [
						{ type: 'array', required: true, message: '请选择角色', trigger: ['blur', 'change'] },
					],
					status: [{ required: true, message: '请选择用户状态', trigger: 'blur' }],
				},
			};
		},
		created() {
			this.queryUserEntryList();
		},
		methods: {
			dateFormat,
			dateTimeFormat,
			authorization,
			queryUserEntryList() {
				this.axios({
					method: 'post',
					url: '/v1/web/user-entry/select-user-entry',
					data: this.params,
				}).then((res) => {
					this.tableData = res.data.data.list;
					this.total = res.data.data.total;
				});
			},
			statusFormat: function (row) {
				if (row.approveStatus === 0) {
					return '待审核';
				} else if (row.approveStatus === 1) {
					return '审核通过';
				} else if (row.approveStatus === 2) {
					return '审核驳回';
				}
			},
			isApproveStatus: function (row) {
				if (row.approveStatus === 0) {
					return true;
				}
				return false;
			},
			approveSuccess(row) {
				console.log(row);
				this.userArray = {};
				this.userArray.entryId = row.id;
				this.userArray.account = row.telephone;
				this.userArray.password = '123456';
				this.userArray.userName = row.name;
				this.userArray.phone = row.telephone;
				this.userArray.address = row.address;
				this.userArray.idCard = row.idCard;
				this.userArray.quitStatus = 1;
				this.userArray.receivingOrder = 0;
				this.userArray.status = 0;
				this.userArray.inductionTime = new Date();
				this.userArray.age = row.age;
				this.userArray.sex = row.sex;
				this.userArray.electricianLicense = row.electricianLicense;
				this.userArray.electricianExpDate = new Date(row.electricianExpDate);
				this.userArray.openId = row.openId;
				if (row.job == '0') {
					this.userArray.roleIdList = [3];
				} else{
					this.userArray.roleIdList = [2];
				}

				this.userArray.idCardFrontImageUrl = row.idCardFrontImageUrl;
				this.userArray.idCardBackImageUrl = row.idCardBackImageUrl;
				this.userArray.electricianImageUrl = row.electricianImageUrl;
				this.userArray.bankCardImageUrl = row.bankCardImageUrl;
				this.userArray.selfImageUrl = row.selfImageUrl;
				this.dialogFormAdd = true;
			},
			approveFun(row, status) {
				this.axios({
					method: 'post',
					url: '/v1/web/user-entry/audit-user-entry/' + row.id,
					data: { approveStatus: status },
				}).then((res) => {
					if (res.data.code === 200) {
						this.params.page = 1;
						this.queryUserEntryList();
						this.$message({ type: 'success', message: '审核成功' });
						return true;
					} else {
						this.$message({ type: 'error', message: res.data.message });
						return false;
					}
				});
			},
			deleteUserEntry(row) {
				this.axios({
					method: 'delete',
					url: '/v1/web/user-entry/delete-user-entry/' + row.id,
					params: {
						id: row.id,
					},
				}).then((res) => {
					if (res.data.code === 200) {
						this.queryUserEntryList();
					} else {
						this.$message({ type: 'error', message: res.data.message });
					}
				});
			},
			handleCurrentChange(val) {
				this.params.page = val;
				this.queryUserEntryList();
			},
			sizeChange(val) {
				this.params.size = val;
				this.params.page = 1;
				this.queryUserEntryList();
			},
			checkData() {
				this.queryUserEntryList();
			},
			reset() {
				this.params = { page: 1, size: 10 };
				this.queryUserEntryList();
			},
			addSubMit() {
				this.userArray = this.$refs.userAdd.userArray;
				this.axios({
					method: 'post',
					url: '/v1/web/user-info/add-user',
					data: this.userArray,
				}).then((res) => {
					if (res.data.code === 200) {
						this.axios({
							method: 'post',
							url: '/v1/web/user-entry/audit-user-entry/' + this.userArray.entryId,
							data: { approveStatus: 1 },
						}).then((res) => {
							if (res.data.code === 200) {
								this.dialogFormAdd = false;
								this.params.page = 1;
								this.queryUserEntryList();
								this.$message({ type: 'success', message: '审核成功' });
								return true;
							} else {
								this.$message({ type: 'error', message: res.data.message });
								return false;
							}
						});
					} else {
						this.$message({ type: 'error', message: res.data.message });
					}
				});
			},
			details(row) {
				this.detailRow = { ...row };
				this.dialogShowDetail = true;
			},
			transformUrl(url) {
				return '/api/v1/web/order-info/preview-image?path=' + url;
			},
			jobFormat(row) {
				if (row.job === '0') {
					return '师傅';
				} else return '客服或其他';
			},
		},
	};
</script>

<style scope>
	.form-userEntry {
		padding-top: 20px;
		padding-left: 20px;
		margin-bottom: 20px;
		background-color: #fff;
		border-radius: 8px;
	}
	.grid {
		height: 82%;
		background-color: #fff;
		border-radius: 8px;
		padding-top: 8px;
	}
	.pager {
		padding-top: 20px;
		height: 40px;
		background-color: #fff;
		width: 100%;
		border-radius: 8px;
		text-align: right;
	}
</style>
